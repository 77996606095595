@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}

body {
  background: #E8E9EB !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  border-color: #255B71;
  box-shadow: none;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.btn-primary {
  background: #255B71 !important;
  border-color: #255B71 !important;
  color: #ffffff !important;
}

.Dashboard_table {
  background: #ffffff;
  border-radius: 10px;
  margin: 0px 30px;
}

.Dashboard_table_logo {
  margin: 25px 0px 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Dashboard_table_title {
  font-size: 25px;
  font-weight: 600;
  margin: 5px 30px 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.refresh_btn{
  border: none;
  cursor: pointer;
  background: #24a0ed;
  font-size: 19px;
  color: #fff;
  border-radius: 5px;
  margin-left: 10px;
}

.table_header {
  cursor: pointer;
}

.error {
  color: red;
}

.active_btn {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  border-radius: 10px;
  border: 0px solid;
  padding: 10px 20px;
}

.deactivate_btn {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  border-radius: 10px;
  border: 0px solid;
  padding: 10px 20px;
}

.padding_btn {
  color: #fff;
  background-color: #515151;
  border-color: #515151;
  border-radius: 10px;
  border: 0px solid;
  padding: 10px 20px;
}

.Dashboard_log_out_btn {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  border-radius: 10px;
  border: 0px solid;
  padding: 10px 20px;

}

.Dashboard_log_out_btn_box {
  text-align: end;
  margin: 30px 30px 10px 30px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.pagination_right_arrow {
  cursor: pointer;
}

.pagination_left_arrow {
  cursor: pointer;
  rotate: 180deg;
}

.pagination_arrow {
  display: flex;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 30px 30px 10px 30px;
  padding-bottom: 20px;
}

.pagination_arrow_box {
  margin-left: 20px;
}

.Dashboard_text_box {
  font-weight: 600;
  margin-right: 15px;
}

.disabled_btn {
  cursor: not-allowed !important;
}

.pencilIcons_btn {
  border: none;
  background: #0000;
  width: 30px;
  margin-right: 20px;
}

.UserDetailsMode_label {
  color: #000;
  font-weight: 500;
  margin: 0px 5px !important;
}

.UserDetailsMode_label_User_Details{
  color: #000;
  font-size: 20px;
  font-weight: 700;
  margin: 0px 5px 10px 5px !important; 

}


.UserDetailsMode_input {
  color: #000;
  border-radius: 5px;
  margin: 5px 0 10px 0 !important;
  padding: 0 5px !important;
  min-width: 400px;
}

.form-submit-button {
  background: #64ad6a;
  color: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
  box-shadow: 5px 5px 5px #eee;
  text-shadow: none;
  padding: 5px 10px;
}

.form-cancel-button {
  background: #024488;
  color: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
  box-shadow: 5px 5px 5px #eee;
  text-shadow: none;
  padding: 5px 10px;
}

.form-button {
  display: flex;
  text-align: center;
  justify-content: center;
}

/* loader */
.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 70px;
  height: 70px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ActionsModel_Btn_Box {
  display: flex;
  align-items: center;
  justify-content: center
}

.DeleteModel_Btn_Box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}


.ActionsModel_Yes_Btn {
  margin: 0 5px;
}

.ActionsModel_No_Btn {
  margin: 0 5px;
  border-color: #00000000;
  background: #D3D3D3;
  color: #000;
}

.DeleteModel_No_Btn {
  margin: 0 5px;
  border-color: #00000000;
  background: #D3D3D3;
  color: #000;
  width: 105px;
}


.MuiTablePagination-selectLabel,.MuiTablePagination-displayedRows {
  margin-bottom: 0 !important;
}


/* Styles for wrapping the search box */

.main {
    width: 100%;
    position: relative;
}

.title-btn{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.form {
  position: relative;
  width: 200px;
}
.form input {
  width: 100%;
  padding-right: 20px;
  box-sizing: border-box;
}
.form input:placeholder-shown+button {
  opacity: 0;
  pointer-events: none;
}
.form button {
  position: absolute;
  border: none;
  display: block;
  width: 23px;
  height: 23px;
  line-height: 16px;
  font-size: 12px;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  right: 5px;
  margin: auto;
  background: #ddd;
  padding: 0;
  outline: none;
  cursor: pointer;
  transition: .1s;
  
  display: flex;
  align-items: center;
  justify-content: center;
}

.has-search .form-control-feedback {
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    top: 0;
}

.close-img{
  width: 15px;
  height: 15px;
  cursor: pointer;
}